<template>
  <div class="gyjg_box" ref="gyjg_box">
    <h1>光影经管</h1>
    <div class="video_box">
      <nav v-for="(item,i) in videoList.list" :key="i" @click="video_play(item)">
        <img :src="baseUrl+item.thumb" alt="">
        <p>{{item.title}}</p>
      </nav>
    </div>
     <!-- 底部分页 -->
      <div class="pagination">
        <!-- <Pagination :total="thach_list.total" :page="thach_list.page" :limit="thach_list.limit" @pageChange="pageChanges"/> -->
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges"/>
      </div>
      <!-- 视频弹框 -->
    <el-dialog draggable append-to-body top="2%" v-model="dialogVisible" :width="1500/128+'rem'" :before-close="handleClose" @close="close" z-index="99999">
      <video :src="baseUrl+video_src_" class="video_gyjg" autoplay controls preload="auto" ref="video_gyjg"/>
    </el-dialog>
  </div>
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import * as axios from '@/api/API.js'
import { ref,reactive,onMounted } from 'vue'
const gyjg_box = ref(null)
const baseUrl = process.env.VUE_APP_BASE_URL
const from = reactive({
  page:1,
  limit:15,
  total:0,
  type:1
})
const videoList = reactive({
  list:[]
})
// 获取光影经管视频列表
const get_video_list = async () => { 
    const {data:res} = await axios.getHttp('/api/video',from)
    if(res.code == 200){
      gyjg_box.value.style.transform = 'translateY(0)'
      gyjg_box.value.style.opacity = 1
      videoList.list = res.data.data
      from.total = res.data.total
    }
  }
get_video_list()

// 分页
const pageChanges = (ee) => {
  from.page = ee
  get_video_list()
}
// 页面加载给予动画
onMounted(() => {
  gyjg_box.value.style.transform = 'translateY(500px)'
  gyjg_box.value.style.opacity = 0
})
let dialogVisible = ref(false)
let video_src_ = ref('')
const video_play = (item) => {
  dialogVisible.value = true
  video_src_.value = item.url
}
// 关闭弹框，暂停视频
const video_gyjg = ref(null)
const close = () => {
 video_gyjg.value.pause()
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.gyjg_box{
  width: 100%;
  font-size: 15/@maxw;
  transition: ease .8s;
  cursor: pointer;
  >h1{
    font-size: 39/@maxw;
    font-family: fantBold;
      font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 10/@maxw 0;
  }
  .video_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30/@maxw 0;
    >nav{
      position: relative;
      width: 540/@maxw;
      min-width: 535/@maxw;
      margin: 30/@maxw 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: ease .3s;
      img{
        width: 100%;
        height: 278/@maxw;
      }
      >img:hover{
      }
      p{
        margin: 10/@maxw 0 0 0;
        padding: 0;
        width: 100%;
        height: 74/@maxw;
        background-image: url("../../../assets/gyjg.png");
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20/@maxw;
      }
    }
    >nav:nth-child(2n){
      margin: 30/@maxw 0 0 0/@maxw;
    }
    >nav::after{
    position: absolute;
    top: 125/@maxw;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 46/@maxw;
    height: 46/@maxw;
    background-image: url("../../../assets/erjigybf.png");
    background-size: 100%;
  }
  >nav:hover{
    box-shadow: 0 0 20/@maxw rgb(90, 90, 90);
  }
  }
  
    .pagination{  
    display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
  }
}
.video_gyjg{
  padding: 0;
  margin: -20/@maxw 0 0 0;
  width: 100%;
  height: 800/@maxw;
}
</style>